.frame-container {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 25px;
  width: 300%;
  /* enlarge beyond browser width */
  left: -100%;
  /* center */
}

.frame-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.darken-image {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .50);
  background-size: cover;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}